import React, { useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairButton from "../../../theme/styled/FairButton";

import { AuthConsumer } from "../../../contexts/AuthProvider";

const SwitchUserModal = () => {
  const [open, setOpen] = useState(true);
  const { user, elevatedUser, tempUser, switchAuth } = AuthConsumer();

  const tempUserIsElevated = useCallback(() => {
    return elevatedUser?.LegalEntityId === tempUser?.LegalEntityId;
  }, [elevatedUser.LegalEntityId, tempUser.LegalEntityId]);

  const toggleModal = () => {
    setOpen(!open);
  };

  const userName = user?.FirstName
    ? `${user?.FirstName} ${user?.LastName}`
    : user.Name;
  const tempUserName = tempUser?.FirstName
    ? `${tempUser?.FirstName} ${tempUser?.LastName}`
    : tempUser.Name;

  return (
    <div>
      <Modal open={open}>
        <FairModalBox sx={{ height: 375 }}>
          <Box
            sx={{
              height: 50,
              px: 4,
              pb: 5
            }}
          >
            <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
              Välj aktivt konto
            </Typography>
          </Box>
          <Box
            sx={{
              height: 170,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Typography
              variant="body2"
              align="left"
              color="black.common"
              sx={{ mb: 1 }}
            >
              Du är för närvarande inloggad som <b>{tempUserName}</b> i en annan
              flik. Hur vill du fortsätta i detta fönster?
            </Typography>
          </Box>
          <Box
            sx={{
              height: 100,
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Button
              onClick={() => {
                switchAuth(!tempUserIsElevated() ? tempUser : null);
                toggleModal();
              }}
              sx={{
                my: 0,
                color: "primary.lighter"
              }}
            >
              Fortsätt som {tempUserName}
            </Button>
            <FairButton
              onClick={() => {
                switchAuth(user);
                toggleModal();
              }}
              sx={{
                my: 0,
                color: "primary.main"
              }}
            >
              Fortsätt som {userName}
            </FairButton>
          </Box>
        </FairModalBox>
      </Modal>
    </div>
  );
};

export default SwitchUserModal;
